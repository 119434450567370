import React, { useEffect, useState } from 'react';
import DoxAI from '../../../assets/images/logo/doxAI-logo.png';
import AccountDetailsServices from '../../../services/AccountDetailsServices';

const MarketingPage = () => {
  const [doxUrl, setDoxUrl] = useState('');
  const doxAPIUrl = async () => {
    try {
      const res = await AccountDetailsServices.redirectToDoxAI();
      setDoxUrl(res?.data);
    } catch (error) {
      console.error('API call failed:', error);
      return error;
    }
  };

  useEffect(() => {
    doxAPIUrl();
  }, []);

  const product = [
    //   {
    //   type: 'Referrals',
    //   imageUrl: 'https://portal-test.appreci.io/services/storage/Product/kfidA5E6UkiVq4OlErSryg.png'
    // }, {
    //   type: 'Promotions',
    //   imageUrl: 'https://portal-test.appreci.io/services/storage/Product/kfidA5E6UkiVq4OlErSryg.png'
    // },
    // {
    //   type: 'DoxAI',
    //   imageUrl: DoxAI,
    //   redirectUrl: doxUrl,
    // },
  ];

  const handleItem = redirectUrl => {
    window.open(redirectUrl, '_blank');
  };

  return (
    <div className="m-4 card border-0 border-radius-5">
      <div className="card-body p-3">
        <p className="h4">Apps</p>
        <div className="d-flex">
          {product.map((elem, index) => (
            <div
              className="p-0 m-0 mr-3 d-flex flex-column position-relative cursor-pointer"
              style={{ width: 'fit-content' }}
              onClick={() => handleItem(elem.redirectUrl)}
            >
              <img
                className={'border-primary-color-2 border-radius-11'}
                src={elem.imageUrl}
                height="150px"
                width="150px"
              />
              <div className="w-120 justify-content-between align-items-center">
                <p className="p-0 m-0 mt-2 color-purple-brown font-14 font-sofia-pro-regular">{elem.type}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MarketingPage;